import Link from 'next/link'
import { useCallback, useContext } from 'react'

import ModalContext from '../../../context/modal'
import Trans from '../../../shared/components/trans'

import { ReactComponent as Biker } from '../../../../svg/biker.svg'
import { ReactComponent as PayAndCollect } from '../../../../svg/pay-and-collect.svg'
import { ReactComponent as Reservation } from '../../../../svg/reservation.svg'

import '../../../../scss/react/features/buying-options-banner.scss'

import Banner from '../../../../img/buying-options-banner-map.webp'
import { LazyImage } from '../../../shared/components/figure'

const BuyingOptionsBanner = ({ brand, slug }) => {
    const { openModal } = useContext(ModalContext)

    const handleClick = useCallback(() => {
        openModal({
            closeButton: true,
            className: 'modal-buying-options',
            render: ({ onCloseModal }) => (
                <div className="modal-box">
                    <div className="inner">
                        <h3>
                            <Trans
                                dutch
                                label="Naar de winkel of laten bezorgen?"
                            />
                        </h3>
                        <p>
                            <Trans
                                dutch
                                label="Op %{brand} verschilt per winkel of je er kunt reserveren, laten bezorgen of click & collect kunt gebruiken."
                                values={{
                                    brand,
                                }}
                            />
                        </p>
                        <p>
                            <Trans
                                dutch
                                label="Aan de iconen hieronder kun je zien welke opties een winkel aanbiedt. Je kunt hier ook op filteren, zodat je bijvoorbeeld alleen winkels ziet die bezorgen."
                            />
                        </p>
                        <ul>
                            <li>
                                <Reservation />
                                <div>
                                    <h5>
                                        <Trans dutch label="Reserveren" />
                                    </h5>
                                    <p>
                                        <Trans
                                            dutch
                                            label="Online reserveren en de winkel bezoeken."
                                        />
                                        <br />
                                        <Link href="/winkels?reservation=true">
                                            <a onClick={() => onCloseModal()}>
                                                <Trans
                                                    dutch
                                                    label="Winkels waar je kunt reserveren"
                                                />
                                            </a>
                                        </Link>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <Biker />
                                <div>
                                    <h5>
                                        <Trans dutch label="Bezorgen" />
                                    </h5>
                                    <p>
                                        <Trans
                                            dutch
                                            label="Online betalen en laten bezorgen."
                                        />
                                        <br />
                                        <Link
                                            href={`/winkels?delivery=${slug}`}
                                        >
                                            <a onClick={() => onCloseModal()}>
                                                <Trans
                                                    dutch
                                                    label="Winkels die bezorgen"
                                                />
                                            </a>
                                        </Link>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <PayAndCollect />
                                <div>
                                    <h5>
                                        <Trans dutch label="Click & Collect" />
                                    </h5>
                                    <p>
                                        <Trans
                                            dutch
                                            label="Winkels die click & collect aanbieden"
                                        />
                                        <br />
                                        <Link href="/winkels?collect=true">
                                            <a onClick={() => onCloseModal()}>
                                                <Trans
                                                    dutch
                                                    label="Winkels waar je kunt afhalen"
                                                />
                                            </a>
                                        </Link>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            ),
        })
    }, [openModal, brand, slug])

    return (
        <div className="buying-options-banner">
            <div className="buying-options-banner-teaser">
                <h5>
                    <Trans dutch label="Naar de winkel of laten bezorgen?" />
                </h5>
                <p>
                    <Trans
                        dutch
                        label="Op %{brand} verschilt per winkel of je er kunt reserveren, laten bezorgen of click & collect kunt gebruiken."
                        values={{
                            brand,
                        }}
                    />
                </p>
                <div className="buttons">
                    <button
                        className="btn btn-medium btn-brand"
                        onClick={handleClick}
                    >
                        <Trans dutch label="Meer informatie" />
                    </button>
                    <Link href="/winkels/kaart">
                        <a className="btn btn-medium">
                            <Trans dutch label="Toon kaartweergave" />
                        </a>
                    </Link>
                </div>
            </div>
            <figure className="cover">
                <LazyImage url={Banner.src} alt="Kaartweergave" />
            </figure>
        </div>
    )
}

export default BuyingOptionsBanner
