import Link from 'next/link'
import React, { useCallback, useContext } from 'react'

import { useItem } from '@zupr/hooks/request-redux'
import { t } from '@zupr/i18n'
import { Location } from '@zupr/types/fo'
import { EntrepreneurType } from '@zupr/types/graphql'
import { imageProviderHashToUrl } from '@zupr/utils/image'
import { locationUrl, storyUrl } from '@zupr/utils/url'

import ModalContext from '../../../context/modal'
import Figure from '../../../shared/components/figure'
import { NativeShare, Socials } from '../../../shared/components/share'
import Trans from '../../../shared/components/trans'
import SiteFull from '../../components/site-full'

import '../../../../scss/react/frontoffice/entrepreneur.scss'

interface EntrepreneurLocationProps {
    location: Location
    onCloseModal?: () => void
}

const EntrepreneurLocation = ({
    location,
    onCloseModal,
}: EntrepreneurLocationProps) => {
    const handleClose = useCallback(() => {
        if (onCloseModal) onCloseModal()
    }, [onCloseModal])

    if (!location) return null
    const { title, images, address, number, zipcode, city } = location

    return (
        <li>
            <Link href={locationUrl(location, 'home')}>
                <a onClick={handleClose}>
                    <Figure
                        cover
                        url={
                            images?.[0] &&
                            imageProviderHashToUrl(
                                { id: images[0].image_provider_hash },
                                'webp',
                                'rs:fit:380:380/enlarge:1'
                            )
                        }
                    />
                    {location && (
                        <div>
                            <h2>{title}</h2>
                            <p>
                                {address} {number}
                                {', '}
                                {zipcode} {city}
                            </p>
                            <span className="link">
                                <Trans label="View assortment" />
                            </span>
                        </div>
                    )}
                </a>
            </Link>
        </li>
    )
}

interface ParagraphsProps {
    text: string
    className?: string
}

const Paragraphs = ({ text, className }: ParagraphsProps) => {
    const paragraphs = text && text.split(/\r\n/g)
    return (
        <React.Fragment>
            {paragraphs.map((paragraph, i) => (
                <p className={className} key={`paragraph-${i}-${paragraph}`}>
                    {paragraph}
                </p>
            ))}
        </React.Fragment>
    )
}

export const Profile = ({
    owner,
    jobDescription,
    quote,
    intro,
    text,
    photo,
    location,
    ...props
}: any) => (
    <div className="entrepreneur-profile">
        <h1>
            {'"'}
            {quote}
            {'"'}
        </h1>
        {!intro.includes('<p>') && (
            <Paragraphs text={intro} className="intro" />
        )}
        {intro.includes('<p>') && (
            <p className="intro">
                <Trans label={intro} html />
            </p>
        )}
        <img src={photo} alt={`${owner}, ${jobDescription}`} />
        <caption>
            {owner}
            {', '}
            {jobDescription}
        </caption>
        {!text.includes('<p>') && <Paragraphs text={text} />}
        {text.includes('<p>') && (
            <p className="text">
                <Trans label={text} html />
            </p>
        )}
        {location && (
            <div className="entrepeneur-share">
                {t('Share')}
                {':'}
                <div>
                    <NativeShare
                        className="btn-social social-mail"
                        url={`/ondernemer/${location.id}`}
                    />
                    <Socials url={`/ondernemer/${location.id}`} />
                </div>
            </div>
        )}
        {location && (
            <ul className="entrepeneur-locations">
                <EntrepreneurLocation
                    {...props}
                    key={location.id}
                    location={location}
                />
            </ul>
        )}
    </div>
)

export const Modal = ({ locationId, ...props }) => {
    const [location] = useItem<Location>({
        url: `fo/location/${locationId}`,
    })

    return (
        <div className="modal-box">
            <div className="inner">
                <Profile {...props} location={location} />
            </div>
        </div>
    )
}

interface StoryModalLinkProps {
    className?: string
    children: React.ReactNode
    story: EntrepreneurType
}

export const StoryModalLink = ({
    className,
    children,
    story,
}: StoryModalLinkProps) => {
    const { openModal } = useContext(ModalContext)

    const handleOpen = useCallback(
        (ev) => {
            ev.preventDefault()
            openModal({
                closeButton: true,
                render: (props) => <Modal {...props} {...story} />,
            })
        },
        [openModal, story]
    )

    return (
        <Link href={storyUrl(story)}>
            <a className={className} onClick={handleOpen}>
                {children}
            </a>
        </Link>
    )
}

const Entrepreneur = ({ location, story }) => {
    if (!story) return null
    return (
        <SiteFull>
            <Profile {...story} location={location} />
        </SiteFull>
    )
}

export default Entrepreneur
