import { Field } from '@zupr/types/form'
import { isValid } from '@zupr/validation/email'
import { useEffect } from 'react'
import { useRequest } from '../request-redux'

interface UseUniqueEmail {
    field: Field
    pause: boolean
}

export const useUniqueEmail = ({ field, pause }: UseUniqueEmail) => {
    const [available] = useRequest({
        url: 'user/mail-available',
        variables: { email: field.value },
        pause: pause || !isValid(field.value),
    })

    useEffect(() => {
        if (typeof available !== 'boolean') return
        if (available) return
        field.setErrors(['There is already a user with this email address.'])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [available])

    return field
}

interface EmailVerifyProps {
    email: Field
    emailVerify: Field
}

export const useEmailVerify = ({ email, emailVerify }: EmailVerifyProps) => {
    useEffect(() => {
        if (!emailVerify.value) return null
        if (!isValid(emailVerify.value)) return null
        if (email.value === emailVerify.value) return null
        emailVerify.setErrors(['De e-mailadressen komen niet overeen'])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email.value, emailVerify.value])
}
