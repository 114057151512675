import { DomainConfig, Location, Product } from '@zupr/types/fo'
import { List } from '@zupr/types/generic'
import { SiteProps } from '@zupr/types/next'
import { roundToNearest } from '@zupr/utils/datetime'
import Frontoffice from '@zupr/web/src/js/frontoffice/components/base'
import Providers from '@zupr/web/src/js/frontoffice/providers'
import Discover from '@zupr/web/src/js/frontoffice/screens/discover'

import { NextRequest } from 'next/server'
import { getList } from '../helpers/fo'
import { getProducts } from '../helpers/products'
import { getProps } from '../helpers/props'

interface getDiscoverProps {
    domainConfig: DomainConfig
    query: { [key: string]: any }
}

interface Props extends SiteProps {
    locations: List<Location>
    products: List<Product>
    discountProducts: List<Product>
    themeProducts: List<Product>[]
}

export const getDiscover = async ({ domainConfig, query }: getDiscoverProps, req: NextRequest) => {
    const { slug, themes } = domainConfig

    const discover = domainConfig.data?.discover

    const randomizeSeed = roundToNearest(5)

    const [locations] = await getList<Location>({
        url: 'fo/location',
        pause: !discover?.includes('locations'),
        variables: {
            limit: 8,
            has_images: true,
            shopping_areas: slug,
            'geo_location__bounding_box': query['box'],
            'randomize': `locations:${randomizeSeed}`
        },
    }, req)

    const products = await getProducts({
        pause: !discover?.includes('products'),
        query,
        params: {
            limit: 8,
            'product_locations.product.has_images': true,
            'product_locations.location.has_images': true,
            'product_locations.shopping_areas': slug,
            'product_locations.stock__range': 0, // hide products not_in_stock
            'randomize': `products:${randomizeSeed}`
        },
    }, req)

    const discountProducts = await getProducts({
        pause: !discover?.includes('discount'),
        query: {
            discount: 'true',
            ...query,
        },
        params: {
            limit: 4,
            'product_locations.product.has_images': true,
            'product_locations.location.has_images': true,
            'product_locations.shopping_areas': slug,
            'product_locations.stock__range': 0, // hide products not_in_stock
            'randomize': `discount:${randomizeSeed}`
        },
    }, req)

    const activeThemes = themes?.filter((theme) =>
        discover?.includes(`theme:${theme}`)
    ) || []

    const themeProducts = await Promise.all(
        activeThemes.map(async (theme) => {
            const products = await getProducts({
                query: {
                    ...query,
                    theme
                },
                params: {
                    limit: 4,
                    'product_locations.product.has_images': true,
                    'product_locations.location.has_images': true,
                    'product_locations.shopping_areas': slug,
                    'product_locations.stock__range': 0, // hide products not_in_stock
                    'randomize': `${theme}:${randomizeSeed}`
                },
            }, req)
            return products
        })
    )

    return {
        locations,
        products,
        discountProducts,
        themeProducts,
    }
}

export async function getServerSideProps({ req, params, query }): Promise<{ props: Props }>  {
    const props = await getProps({ req, params, query })
    const data = await getDiscover({ domainConfig: props.domainConfig, query: props.query }, req)
    return {
        props: {
            ...props,
            ...data,
        },
    }
}

const Index = ({
    locations,
    products,
    discountProducts,
    themeProducts,
    ...props
}: Props) => (
    <Providers {...props}>
        <Frontoffice>
            <Discover
                locations={locations}
                products={products}
                discountProducts={discountProducts}
                themeProducts={themeProducts}
            />
        </Frontoffice>
    </Providers>
)

export default Index
