import { gqlFetch, gqlKey } from '@zupr/queries/utils';
import { Query } from '@zupr/types/graphql';
import { NextApiRequest } from 'next';
import { NextRequest } from 'next/server';

import { getCache, setCache } from './cache';

interface DoQueryProps<Variables extends Record<string, any>> {
    query: any
    variables: Variables
    ex?: number
}

interface DoQueryResult {
    data?: Query
}

async function doQuery<Variables extends Record<string, any>>({
    query,
    variables,
    ex,
}: DoQueryProps<Variables>, req: NextRequest | NextApiRequest): Promise<DoQueryResult> {
    
    const key = gqlKey({
        variables,
        query,
    })

    const cache = await getCache(key)
    if (cache) {
        return cache
    }

    const result = await gqlFetch({
        url: `https://${process.env.NEXT_PUBLIC_HOST_API}/graphql`,
        variables,
        query,
    }, req)

    if (ex !== 0) setCache(key, result, ex || 30)

    return result
}

export default doQuery
